//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        mode: {
            type: String,
            default: 'dropdown',
            validator: function (value) {
                return ['dropdown', 'modal'].includes(value)
            }
        }
    },
    methods: {
        dismiss: function () {
            if (this.$parent && this.$parent.showLocaleMenu) {
                this.$parent.showLocaleMenu = false
            }
        }
    }
}
