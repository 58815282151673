import { get, startsWith } from 'lodash';
import URI from 'urijs';

const handle = async function ({ app, redirect, route, $contentful }, locale) {
    const path = route.path;

    if (startsWith(path, `/${locale}`)) {
        const from = path.substring(locale.length + 1);
        const rule = await $contentful.getRoutingRule(from ? from : '/');

        if (rule) {
            const to = get(rule, 'fields.to');
            const uri = new URI(to);

            if (uri.is('absolute')) {
                redirect(to);
            }

            if (uri.is('relative')) {
                redirect(app.localePath(to));
            }
        }
    } else {
        // This shouldn't happen if nuxt-i18n is working properly
    }
}

export default async function (context) {
    const { i18n } = context;

    await handle(context, i18n.locale);

    // When switching language, `i18n.locale` isn't updated yet in this moment
    i18n.onLanguageSwitched = function (oldLocale, newLocale) {
        handle(context, newLocale);
    }
}
