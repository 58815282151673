//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import IconContact from '@/components/icons/IconContact.vue'
import IconLocation from '@/components/icons/IconLocation.vue'

export default {
    props: {
        sections: {
            type: Array,
            required: true
        }
    },
    components: {
        IconLocation,
        IconContact
    },
    methods: {
        get,
        getIcon (index) {
            return index === 0 ? 'IconContact' : 'IconLocation'
        }
    }
}
