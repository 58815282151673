//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fontMixin from '@/mixins/font'

export default {
    mixins: [fontMixin],
    head: function () {
        return this.$nuxtI18nHead({ addSeoAttributes: true })
    }
}
