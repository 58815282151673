//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    methods: {
        backToTop () {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
