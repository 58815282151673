export default ({ i18n, $userAgent }) => {
    // States
    let initialized = false;

    const initializeClient = function () {
        // Copied from any widget in https://embedsocial.com/admin/social_feed
        (function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"));

        initialized = true;
    }

    initializeClient();
}
