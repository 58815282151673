import { get } from 'lodash';

const menus = {};

export const getMenu = async (name, locale) => {
    // `locale` here is the legacy code, i.e. $i18n.localeProperties.alias

    const cache = get(menus, `${name}.${locale}`, null);
    if (cache) {
        return cache;
    }

    try {
        const url = `https://d3w3o0h1dy30mi.cloudfront.net/storage3/jsondata/${name}.${locale}.json`
        const response = await fetch(url)
        let data = await response.json()
        if (data) {
            // TODO: To be enhanced
            if (name === 'footer') {
                data = data.map(item => {
                    return item.children;
                });
            }

            menus[`${name}.${locale}`] = data;

            return data;
        }
    } catch {
    }

    return [];
}
