//
//
//
//
//
//

export default {
    props: {
        to: {
            type: [Object, String],
            required: true
        },
        target: {
            type: String,
            default: 'auto'
        }
    }
}
