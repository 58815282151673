import Vue from 'vue'
import { createClient } from 'contentful'
import { filter, get, head, includes, isEmpty, isString, join, map, toArray, toLower } from 'lodash'
import pageMixin from '@/mixins/contentful-page'

const config = {
    space: process.env.CONTENTFUL_SPACE,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    host: process.env.CONTENTFUL_HOST,
    environment: process.env.CONTENTFUL_ENVIRONMENT,
    timeout: 60000
}

const client = createClient(config)

export default (context, inject) => {
    inject('contentful', {
        ...client,

        // Custom methods go here
        getNuxtContext: function () {
            return context
        },
        getLocale: function () {
            const { i18n } = this.getNuxtContext()
            return i18n.locale
        },
        getRegion: function () {
            const { i18n } = this.getNuxtContext()
            return i18n.localeProperties.region
        },
        getPages: async function (types, slugs, query) {
            let params = {
                content_type: 'page',
                include: 10,
                locale: this.getLocale(),
                'fields.region': this.getRegion()
            }

            if (isString(types)) {
                params['fields.type'] = types
            } else if (!isEmpty(types)) {
                params['fields.type[in]'] = join(toArray(types), ',')
            }

            if (isString(slugs)) {
                params['fields.slug'] = slugs
            } else if (!isEmpty(slugs)) {
                params['fields.slug[in]'] = join(toArray(slugs), ',')
            }

            if (!isEmpty(query)) {
                params = { ...params, ...query }
            }

            const { items } = await this.getEntries(params)

            return items
        },
        getPagesByTag: async function (tag, query) {
            let params = {
                content_type: 'page',
                include: 10,
                locale: this.getLocale(),
                'fields.region': this.getRegion(),
                'metadata.tags.sys.id[in]': tag
            }

            if (!isEmpty(query)) {
                params = { ...params, ...query }
            }

            const { items } = await this.getEntries(params)

            return items
        },
        getPage: async function (type, slug) {
            const params = {
                content_type: 'page',
                include: 10,
                locale: this.getLocale(),
                'fields.region': this.getRegion(),
                'fields.type': type,
                'fields.slug': slug
            }

            const { items } = await this.getEntries(params)

            return head(items)
        },
        getSection: async function (slug) {
            const params = {
                content_type: 'section',
                include: 10,
                locale: this.getLocale(),
                'fields.slug': slug
            }

            const { items } = await this.getEntries(params)

            return head(items)
        },
        getRoutingRule: async function (path) {
            // Support case insensitivity by using `[match]` on `from` first
            const params = {
                content_type: 'routingRule',
                include: 10,
                locale: this.getLocale(),
                'fields.from[match]': path,
                'fields.regions': this.getRegion()
            }

            let { items } = await this.getEntries(params)

            // Find the exact match
            items = filter(items, function (item) {
                return toLower(get(item, 'fields.from')) === toLower(path)
            })

            return head(items)
        },
        getPageLocales: function (page) {
            const { i18n } = this.getNuxtContext()

            const pageRegions = get(page, 'fields.region')
            const pageLocales = filter(i18n.locales, function (locale) {
                return includes(pageRegions, locale.region)
            })

            return map(pageLocales, 'code')
        }
    })

    Vue.mixin(pageMixin)
}

// For backward compatibility, will be removed
// Usage: import { contentful } from '@/plugins/contentful'
export const contentful = client
