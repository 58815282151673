import { render, staticRenderFns } from "./AppNavOverlay.vue?vue&type=template&id=4725f245&"
import script from "./AppNavOverlay.vue?vue&type=script&lang=js&"
export * from "./AppNavOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavIcons: require('/var/www/html/spa-website/components/appnav/NavIcons.vue').default,DynamicLink: require('/var/www/html/spa-website/components/DynamicLink.vue').default,IconContact: require('/var/www/html/spa-website/components/icons/IconContact.vue').default,IconLocation: require('/var/www/html/spa-website/components/icons/IconLocation.vue').default,IconLang: require('/var/www/html/spa-website/components/icons/IconLang.vue').default,MobileLocalePop: require('/var/www/html/spa-website/components/appnav/MobileLocalePop.vue').default,IconPrev: require('/var/www/html/spa-website/components/icons/IconPrev.vue').default,Button: require('/var/www/html/spa-website/components/Button.vue').default})
