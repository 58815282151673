//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside'
export default {
    props: {
        colorMode: {
            type: String,
            default: 'light'
        },
        open: {
            type: Boolean
        }
    },
    data () {
        return {
            showLocalePop: false
        }
    },
    computed: {
        themeClasses: function () {
            return {
                icon: {
                    'text-black hover:text-gray-300': this.colorMode === 'light',
                    'text-white hover:text-gray-700': this.colorMode === 'dark'
                }
            }
        }
    },
    directives: {
        ClickOutside,
    },
    methods: {
        toggle () {
            this.showLocalePop = !this.showLocalePop
        },
        close () {
            this.showLocalePop = false
        }
    }
}
