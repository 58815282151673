//
//
//
//
//
//

export default {
    props: {
        type: {
            type: String,
            default: 'button',
            validator: function (value) {
                return ['button', 'submit', 'reset', 'toggle'].includes(value)
            }
        },
        buttonStyle: {
            type: String,
            default: 'solid',
            validator: function (value) {
                return ['solid', 'outline'].includes(value)
            }
        }
    },
    data: function () {
        return {
            toggleEvent: null,
            active: false
        }
    },
    computed: {
        cssClasses: function () {
            switch (this.buttonStyle) {
                case 'outline':
                    return {
                        'border-button-primary text-button-primary': this.type !== 'toggle',
                        'border-button-active text-button-active': this.type === 'toggle' && this.active,
                        'border-button-inactive text-button-inactive': this.type === 'toggle' && !this.active
                    }

                case 'solid':
                default:
                    // Currently solid button doesn't support any status
                    return 'bg-button-primary border-button-primary text-white disabled:bg-button-inactive disabled:border-button-inactive disabled:cursor-not-allowed'
            }
        }
    },
    methods: {
        onToggle: function (e) {
            this.active = !this.active
            e.active = this.active
            this.$emit('toggle', e)
        }
    },
    created: function () {
        if (this.type === 'toggle') {
            this.toggleEvent = 'click'
        }
    }
}
