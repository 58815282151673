//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: ['error'],
    head: function () {
        return {
            title: this.title
        }
    },
    computed: {
        title: function () {
            switch (get(this.error, 'statusCode')) {
            case 404: return 'Page not found'
            case 500: return 'Internal server error'
            default: return 'Error'
            }
        },
        message: function () {
            const message = get(this.error, 'message')

            if (typeof message === 'boolean') {
                return message ? this.title : ''
            }

            if (typeof message === 'string') {
                return message
            }

            return 'Unknown error'
        }
    }
}
