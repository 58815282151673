import { isString, toUpper } from 'lodash'

export default (context, inject) => {
    inject('upperTitle', function (string) {
        if (!isString(string)) {
            return ''
        }

        let result = ''
        for (let i = 0; i < string.length; i++) {
            const chr = string.charAt(i)
            const chrCode = string.charCodeAt(i)

            // Uppercase a-z only
            if (chrCode >= 97 && chrCode <= 122) {
                result += toUpper(chr)
            } else {
                result += chr
            }
        }

        return result
    })
}
