import { filter, get, groupBy, uniqWith } from 'lodash'
import { fetchContents } from './index'

// example: https://www.shiseido-professional.com/en/range/hair-care/sublimic-fuenteforte
export const fetchAllProductRange = async () => {
  // Fetch all product range pages
  let productRangePages = await fetchContents({ 'fields.type': 'productRange' });

  // Sort the pages by 'Custom Order', in ascending order
  productRangePages = productRangePages.sort((a, b) => {
    return get(a, 'fields.reference.fields.customOrder', 0) - get(b, 'fields.reference.fields.customOrder', 0);
  })

  let middleLevelContents = {}
  for (let i = 0; i < productRangePages.length; i++) {
    const range = get(productRangePages[i], 'fields.reference.fields.self');

    const childPages = filter(productRangePages, (page) => {
      return get(page, 'fields.reference.fields.parentRange') === range && range
    })

    if (childPages.length !== 0) {
      middleLevelContents = {
        ...middleLevelContents,
        [range]: { ...productRangePages[i], childPages }
      }
    }
  }

  const addedMiddleLevel = productRangePages.sort((a, b) => {
    return get(a, 'fields.reference.fields.rootProductRange.fields.customOrder', 0) - get(b, 'fields.reference.fields.rootProductRange.fields.customOrder', 0);
  }).map(value => get(middleLevelContents, 'value.fields.title', value))

  const mergedContent = filter(addedMiddleLevel, value => {
    if (get(value, 'fields.reference.fields.parentRange')) {
      return !middleLevelContents[value.fields.reference.fields.parentRange];
    } else {
      return false;
    }
  })

  const groupedContents = groupBy(mergedContent, (content) => {
    return get(content, 'fields.reference.fields.rootProductRange.sys.id');
  })

  return groupedContents
}

export const fetchProducts = async (text) => {
  const matchTitleProducts = await fetchContents({ 'fields.title[match]': text })
  const matchNameProducts = await fetchContents({ 'fields.name[match]': text })
  const all = matchTitleProducts.concat(matchNameProducts)
  return uniqWith(all, (lhs, rhs) => {
    return lhs.sys.id === rhs.sys.id
  })
}
