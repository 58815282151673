import { get, startsWith } from 'lodash'

export default {
    methods: {
        pageHead: function (page) {
            const meta = get(page, 'fields.metaData')

            const title = get(meta, 'fields.title', get(page, 'fields.title')).replaceAll('<br>', ' ')

            const head = {
                title,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: get(meta, 'fields.description', get(page, 'fields.description'))
                    },
                    {
                        hid: 'og:title',
                        property: 'og:title',
                        content: title
                    },
                    {
                        hid: 'og:type',
                        property: 'og:type',
                        content: get(meta, 'fields.type', 'website')
                    },
                    {
                        hid: 'og:description',
                        property: 'og:description',
                        content: get(meta, 'fields.description', get(page, 'fields.description'))
                    },
                    {
                        hid: 'og:url',
                        property: 'og:url',
                        content: this.pageLocalePath(page)
                    }
                ],
                link: []
            }

            const keywords = get(meta, 'fields.keyword')
            if (keywords) {
                head.meta.push({
                    hid: 'keywords',
                    name: 'keywords',
                    content: keywords
                })
            }

            const imageUrl = get(meta, 'fields.image.fields.file.url', get(page, 'fields.heroImage.fields.file.url'))
            if (imageUrl) {
                head.meta.push({
                    hid: 'og:image',
                    property: 'og:image',
                    content: `https:${imageUrl}`
                })
            }

            if (get(meta, 'fields.displaySmartAppBanners')) {
                head.meta.push({
                    hid: 'apple-itunes-app',
                    name: 'apple-itunes-app',
                    content: 'app-id=' + this.$config.appleAppId
                })

                head.link.push({
                    rel: 'manifest',
                    href: '/manifest.webmanifest'
                })
            }

            return head
        },
        pageLocalePath: function (page) {
            const type = get(page, 'fields.type')
            const slug = get(page, 'fields.slug')

            switch (type) {
            case 'articlePage':
                return this.localePath(`/for-customer/${slug}`)
            case 'BeautyCreator':
                return this.localePath(`/creators/${slug}`)
            case 'BeautyCreatorsInspire':
            case 'BeautyStream':
            case 'campaignPage':
            case 'editorial':
            case 'inspirationArticle':
                return this.localePath(`/news-and-events/${slug}`)
            case 'product':
                return this.localePath(`/product-detail/${slug}`)
            case 'productRange': {
                const categorySlug = get(page, 'fields.reference.fields.rootProductRange.fields.slug')
                return this.localePath(`/range/${categorySlug}/${slug}`)
            }
            case 'style':
                return this.localePath(`/style/${slug}`)
            case 'System':
            default:
                return this.localePath(`/${slug}`)
            }
        },
        pageCategory: function (page) {
            const type = get(page, 'fields.type')
            const slug = get(page, 'fields.slug')

            switch (type) {
            case 'articlePage':
                return 'Article'
            case 'BeautyCreator':
                return 'Beauty Creators Talk'
            case 'BeautyCreatorsInspire':
            case 'BeautyStream':
            case 'editorial':
            case 'inspirationArticle':
                return 'Trends & Awards'
            case 'campaignPage':
                return 'News'
            case 'product':
                return 'Product'
            case 'productRange':
                return 'Range'
            case 'style':
                return 'Style'
            case 'System':
                break
            default:
                return ''
            }

            // TODO: Consider using content fields (e.g. label) for System pages
            if (type === 'System') {
                switch (true) {
                case (slug === '/about-us') || startsWith(slug, '/about-us/'):
                case slug === '/assets':
                case slug === '/heritage':
                    return 'About Us'
                case (slug === '/app-for-professional') || startsWith(slug, '/app-for-professional/'):
                    return 'For Professional'
                case (slug === '/for-professional') || startsWith(slug, '/for-professional/'):
                    return 'For Professional'
                }
            }
        }
    }
}
