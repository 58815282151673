import { get } from 'lodash'

export function updateDocumentMetadataByPages(pageContents) {
    if (!Array.isArray(pageContents)) {
        return
    }

    pageContents.forEach(item => { updateDocumentMetadataByPage(item) })
}

export function updateDocumentMetadataByPage(pageContent) {
    const head = document.getElementsByTagName('head')[0];
    const metadata = get(pageContent, 'fields.metaData.fields');

    for (const key in metadata) {
        const value = metadata[key];

        if (key !== 'title' && key !== 'description' && typeof value === 'string') {
            let tag = document.querySelector(`head > meta[name="${key}"]`);

            if (tag) {
                // Update existing tag
                tag.content = value;
            } else {
                // Create new tag
                tag = document.createElement('meta');
                tag.name = key;
                tag.content = value;
                head.appendChild(tag);
            }

        }
    }
}
