//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import ClickOutside from 'vue-click-outside'
import { getMenu } from '@/utils/contentfulFetchHelper'

export default {
    data () {
        return {
            actionSections: [],
            showLocaleMenu: false,
            footer: [],
            social: []
        }
    },
    directives: {
        ClickOutside
    },
    watch: {
        '$i18n.locale': function () {
            this.pseudoFetch()
        },
        showLocaleMenu: function (val) {
            document.body.classList.toggle('overflow-hidden', val)
            document.body.classList.toggle('lg:overflow-visible', val)
        }
    },
    methods: {
        async pseudoFetch () {
            const section = await this.$contentful.getSection('contact-us')
            this.actionSections = get(section, 'fields.items', [])

            this.footer = await getMenu('footer', this.$i18n.localeProperties.alias)
            this.social = await getMenu('social', this.$i18n.localeProperties.alias)
        },
        closeIfOpened () {
            if (this.showLocaleMenu) {
                this.showLocaleMenu = false
            }
        },
        onLinkClick (link, event) {
            if (link.custom_action) {
                switch (link.custom_action) {
                case 'none':
                case '':
                    return

                case 'toggle_locale_menu':
                    this.showLocaleMenu = !this.showLocaleMenu
                    break

                default:
                    return
                }

                // If a valid custom action is specified, prevent default
                event.preventDefault()
                event.stopPropagation()
            }
        },
        faClass (iconName) {
            const faIconName = iconName.replace('brand-', '')

            return faIconName === 'twitter' ? 'x-twitter' : faIconName
        }
    },
    async mounted () {
        await this.pseudoFetch()
    }
}
