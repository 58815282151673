import { flattenDeep } from 'lodash';
import { getMenu, fetchAllProductRange } from './index';

export const fetchSitemap = async (locale) => {
    const footer = await getMenu('footer', locale);
    const flattennedFoot = flattenDeep(footer);
    const productRange = await fetchAllProductRange();
    const sitemap = flattennedFoot.map(value => {
        if (value.title === 'Products') {
            return { ...value,  productRange };
        } else {
            return value;
        }
    });

    return sitemap;
}
