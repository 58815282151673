import filepond from './filepond/en-en';

export default {
  less: 'Less',
  more: 'More',

  _suffix_tax_included: '',
  _tax_information: '',

  _app_store_app_url: 'https://apps.apple.com/sg/app/steps-by-shiseido-pro/id1578465832?itsct=apps_box_badge&amp;itscg=30200',
  _app_store_badge_url: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1647216000&h=7f69e7c9f2d75e81eb25c3391e98f814',
  _app_store_badge_title: 'Download on the App Store',
  _google_play_app_url: 'https://play.google.com/store/apps/details?id=com.jp.co.shiseido.steps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  _google_play_badge_url: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
  _google_play_badge_title: 'Get it on Google Play',

  ...filepond,
}
