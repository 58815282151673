import { join, merge } from 'lodash'
import { fetchContents } from './index'

export const newsAndEventType = {
  news: 'News',
  event: 'Event',
  campaign: 'Campaign',
  others: 'Others',
  beautyCreatorsTalk: 'BeautyCreatorsTalk'
}

const pageCount = 5

const fetchContentsWithPagination = async (page = 0, queryParams = {}) => {
  const paginationParams = { limit: 5, skip: page * pageCount }
  const params = merge(paginationParams, queryParams)
  return await fetchContents(params)
}

/**
 * @param {string} locale
 * @param {Array<string>?} locations - China, Global, Hong Kong, Indonesia, Japan, Korea, Malaysia, Singapore, Taiwan, Thailand
 * @param {Array<string>?} types - News, Event, Campaign
 */
export const fetchNewsAndEventByLocationAndType = async (locations, types, page = 0) => {
  const locationParam = locations ? { 'fields.region[all]': join(locations, ',') } : {}
  const typeParam = types && types.length !== 0
    ? { 'fields.label[in]': join(types, ',') }
    : { 'fields.label[in]': join(Object.values(newsAndEventType), ',') }
  const params = merge(locationParam, typeParam, { order: '-fields.date' })
  return await fetchContentsWithPagination(page, params)
}

export const getRegionFilter = () => {
  return {
    filterName: 'LOCATION',
    region: [{ name: 'Global', filter: 'Global' }, { name: 'Hong Kong', filter: 'Hong Kong' },
    { name: 'Indonesia', filter: 'Indonesia' }, { name: 'Japan', filter: 'Japan' },
    { name: 'Korea', filter: 'Korea' }, { name: 'Malaysia', filter: 'Malaysia' },
    { name: 'Singapore', filter: 'Singapore' }, { name: 'Taiwan', filter: 'Taiwan' },
    { name: 'Thailand', filter: 'Thailand' }]
  }
}

export const getTypeFilter = () => {
  return {
    filterName: 'EVENT TYPE',
    type: [{ name: 'News', filter: newsAndEventType.news }, { name: 'event', filter: newsAndEventType.event },
    { name: 'Campaign', filter: newsAndEventType.campaign }, { name: 'others', filter: newsAndEventType.others },
    { name: 'Beauty Creators Talk', filter: newsAndEventType.beautyCreatorsTalk }]
  }
}
