import { pickBy } from 'lodash'
import { contentful } from '../../plugins/contentful'
import { updateDocumentMetadataByPages } from '../meta'
export * from './NewsAndEvents'
export * from './Products'
export * from './Config'
export * from './Sitemap'
export * from './Education'

export const fetchContents = async (queryParams = {}) => {
    const commonParams = {
        content_type: 'page',
        locale: $nuxt.$i18n.locale,
        order: '-sys.createdAt',
        include: 10,
        'fields.region': $nuxt.$i18n.localeProperties.region
    };
    const params = pickBy({ ...commonParams, ...queryParams }, value => {
        return value;
    });
    const promise = contentful.getEntries(params);
    const content = await promise;
    updateDocumentMetadataByPages(content.items);
    return content.items;
}
