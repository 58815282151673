//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, isObject, isString, startsWith } from 'lodash'
import URI from 'urijs'

export default {
    props: {
        to: {
            // Objects are Contentful pages
            type: [Object, String],
            required: true
        },
        target: {
            type: String,
            default: 'auto'
        }
    },
    computed: {
        renderWith: function () {
            if (this.$userAgent.isApp) {
                return 'react-native-link'
            } else if (isObject(this.to) && get(this.to, 'sys.contentType.sys.id') === 'page') {
                return 'nuxt-link'
            } else if (isObject(this.to) && get(this.to, 'sys.contentType.sys.id') === 'item') {
                return 'anchor-tag'
            } else if (startsWith(this.to, '#')) {
                return this.to === '#' ? 'anchor-tag' : 'nuxt-link'
            } else if (this.isAbsolute) {
                return 'anchor-tag'
            } else if (this.isRelative) {
                return 'nuxt-link'
            }

            return null
        },
        url: function () {
            if (this.renderWith === 'nuxt-link') {
                if (isString(this.to) && startsWith(this.to, '#')) {
                    return { hash: this.to }
                }
            }

            if (this.renderWith === 'anchor-tag') {
                if (isString(this.to) && this.to === '#') {
                    return 'javascript:void(0)'
                }
            }

            if (isObject(this.to) && get(this.to, 'sys.contentType.sys.id') === 'page') {
                return this.pageLocalePath(this.to)
            } else if (isObject(this.to) && get(this.to, 'sys.contentType.sys.id') === 'item') {
                return get(this.to, 'fields.url')
            } else if (this.isAbsolute) {
                return this.to
            } else if (this.isRelative) {
                return this.localePath(this.to)
            }

            return null
        },
        isAbsolute: function () {
            const uri = new URI(this.to)
            return uri.is('absolute')
        },
        isRelative: function () {
            const uri = new URI(this.to)
            return uri.is('relative')
        }
    },
    methods: {
        rnOnClick (event) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(get(event.currentTarget, 'attributes.data-url.value'))
            }
        }
    }
}
