//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { getMenu } from '@/utils/contentfulFetchHelper'

export default {
    data () {
        return {
            transparent: false,
            open: false,
            showingLangPop: false,
            header: [],
            social: []
        }
    },
    head: {
        changed: function (newInfo, addedTags, removedTags) {
            $nuxt.$emit('metaChanged', { newInfo, addedTags, removedTags })
        }
    },
    computed: {
        colorMode: function () {
            return this.transparent ? 'dark' : 'light'
        },
        isLightMode: function () {
            return this.colorMode === 'light'
        },
        isDarkMode: function () {
            return this.colorMode === 'dark'
        }
    },
    watch: {
        '$i18n.locale' () {
            this.reloadMenus()
        },
        $route () {
            this.open = false
            this.showingLangPop = false
        },
        open: function (val) {
            document.body.classList.toggle('overflow-hidden', val)
            document.body.classList.toggle('lg:overflow-visible', val)
        }
    },
    methods: {
        async reloadMenus () {
            this.header = await getMenu('header', this.$i18n.localeProperties.alias)
            this.social = await getMenu('social', this.$i18n.localeProperties.alias)
        },
        onLinkClick (link, event) {
            if (link.custom_action) {
                switch (link.custom_action) {
                    case 'none':
                    case '':
                        return

                    case 'toggle_locale_menu':
                        this.toggleLang()
                        break

                    default:
                        return
                }

                // If a valid custom action is specified, prevent default
                event.preventDefault()
            }
        },
        toggleLang () {
            this.showingLangPop = !this.showingLangPop
        },
        toggleMenu () {
            this.open = !this.open
        }
    },
    created: function () {
        const $this = this

        this.$root.$on('metaChanged', function ({ newInfo }) {
            $this.transparent = get(newInfo, 'htmlAttrs.data-navbar-transparent', false)
        })
    },
    async mounted () {
        this.transparent = get(this.$root.$meta().refresh().metaInfo, 'htmlAttrs.data-navbar-transparent', false)
        await this.reloadMenus()
    }
}
