export default {
    watch: {
        '$i18n.locale'() {
            this.updateBodyAttribute();
        }
    },
    methods: {
        updateBodyAttribute() {
            document.body.dataset.locale = this.$i18n.locale;
        }
    },
    mounted () {
        this.updateBodyAttribute();
    }
}
