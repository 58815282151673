import { render, staticRenderFns } from "./LocalePopup.vue?vue&type=template&id=353d8043&scoped=true&"
import script from "./LocalePopup.vue?vue&type=script&lang=js&"
export * from "./LocalePopup.vue?vue&type=script&lang=js&"
import style0 from "./LocalePopup.vue?vue&type=style&index=0&id=353d8043&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353d8043",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/var/www/html/spa-website/components/Button.vue').default})
