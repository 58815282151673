import { startsWith } from 'lodash'
import mobile from 'is-mobile'
import UaParser from 'ua-parser-js'

export default (context, inject) => {
    const userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent
    const parser = process.server ? new UaParser(userAgent) : new UaParser()

    const browserName = parser.getBrowser().name
    const isApp = startsWith(userAgent, 'ShiseidoSTEPS/')
    const isWeb = !isApp
    const isMobile = isApp ? true : mobile(userAgent)
    const isDesktop = !isMobile

    inject('userAgent', {
        value: userAgent,
        browserName,
        isApp,
        isWeb,
        isMobile,
        isDesktop
    })
}
