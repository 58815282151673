import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faCircleNotch as freeFasFaCircleNotch,
  faEnvelope as freeFasFaEnvelope,
  faGlobe as freeFasFaGlobe,
  faGlobeAmericas as freeFasFaGlobeAmericas,
  faMapMarker as freeFasFaMapMarker,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faPhone as freeFasFaPhone,
  faTimes as freeFasFaTimes
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendar as freeFarFaCalendar
} from '@fortawesome/free-regular-svg-icons'

import {
  faFacebook as freeFabFaFacebook,
  faInstagram as freeFabFaInstagram,
  faLine as freeFabFaLine,
  faWeixin as freeFabFaWeixin,
  faWhatsapp as freeFabFaWhatsapp,
  faXTwitter as freeFabFaXTwitter,
  faYoutube as freeFabFaYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaCircleNotch,
  freeFasFaEnvelope,
  freeFasFaGlobe,
  freeFasFaGlobeAmericas,
  freeFasFaMapMarker,
  freeFasFaMapMarkerAlt,
  freeFasFaPhone,
  freeFasFaTimes,
  freeFarFaCalendar,
  freeFabFaFacebook,
  freeFabFaInstagram,
  freeFabFaLine,
  freeFabFaWeixin,
  freeFabFaWhatsapp,
  freeFabFaXTwitter,
  freeFabFaYoutube
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
