import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a829ffe8&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNav: require('/var/www/html/spa-website/components/appnav/AppNav.vue').default,Footer: require('/var/www/html/spa-website/components/footer/Footer.vue').default})
